export const permissionData = [
    {
     app: "CPMR",
     permissions: [
         {
            module:"Dashboard",
         access: {
             create: true,
             view: true,
             edit:true,
             delete: false
                },
            },
         {
            module:"My Patients",
         access: {
             create: true,
             view: true,
             edit:true,
             delete: false
                },
            },
            {
                module:"All Patients",
             access: {
                 create: true,
                 view: true,
                 edit:true,
                 delete: false
                    },
                },
                {
                    module:"Urgent Care Patients",
                 access: {
                     create: true,
                     view: true,
                     edit:true,
                     delete: false
                        },
                    },
                    {
                        module:"Emergency Patients",
                     access: {
                         create: true,
                         view: true,
                         edit:true,
                         delete: false
                            },
                        },
         {
            module:"Consult Requests",
         access: {
             create: true,
             view: true,
             edit:true,
             delete: false
                },
            },
         {
            module:"Nursing",
         access: {
             create: true,
             view: true,
             edit:true,
             delete: false
                },
            },
         {
            module:"Settings",
         access: {
             create: true,
             view: true,
             edit:true,
             delete: false
                },
            },
        ]
    },
    {
     app: "Ambulatory Suite",
     permissions: [
         {
            module: "Dashboard",
            access: {
             create: true,
             view: true,
             edit:true,
             delete: false
         },
        },
         {
            module: "Check In",
            access: {
             create: true,
             view: true,
             edit:true,
             delete: false
         },
        },
         {
            module: "Triage",
            access: {
             create: true,
             view: true,
             edit:true,
             delete: false
         },
        },
         {
            module: "Billing",
            access: {
             create: true,
             view: true,
             edit:true,
             delete: false
         },
        },
         {
            module: "Laboratory",
            access: {
             create: true,
             view: true,
             edit:true,
             delete: false
         },
        },
        {
            module: "Pharmacy Dashboard",
            access: {
             create: true,
             view: true,
             edit:true,
             delete: false
         },
         
        },
        {
            module: "Inventory",
            access: {
             create: true,
             view: true,
             edit:true,
             delete: false
         },
         
        },
        //  {
        //     module: "Inventory",
        //     access: {
        //      create: true,
        //      view: true,
        //      edit:true,
        //      delete: false
        //  },
        // },
        {
            module: "Med Orders",
            access: {
             create: false,
             view: false,
             edit:false,
             delete: false
            },
         
        },
        {
            module: "Sales",
            access: {
             create: false,
             view: false,
             edit:false,
             delete: false
         },
        },
        {
            module: "Pharm Invoices",
            access: {
             create: false,
             view: false,
             edit:false,
             delete: false
         },
        },
        // {
        //     module: "Inpatient Pharmacy",
        //     access: {
        //      create: false,
        //      view: false,
        //      edit:false,
        //      delete: false
        //  },
        // },
         {
            module: "Radiology visit",
            access: {
             create: true,
             view: true,
             edit:true,
             delete: false
         },
        },
    ]
    },
    {
        app: "HR Suite",
        permissions: [
            {
                module: "Dashboard",
                access: {
                 create: true,
                 view: true,
                 edit:true,
                 delete: false
             },
            },
            {
                module: "Staffs",
                access: {
                 create: true,
                 view: true,
                 edit:true,
                 delete: false
             },
            },
            {
                module: "Departments",
                access: {
                 create: true,
                 view: true,
                 edit:true,
                 delete: false
             },
            },
            {
                module: "Designation",
                access: {
                 create: true,
                 view: true,
                 edit:true,
                 delete: false
             },
            },
            {
                module: "Attendance",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },
            {
                module: "My Leave Applications",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },
            {
                module: "Approve Leaves",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },
            {
                module: "Payroll Setup",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },
            {
                module: "Payroll Items",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },
            {
                module: "Process Payroll",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },
            {
                module: "Review Payroll",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },
            {
                module: "Approve Payroll",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },
            {
                module: "Manage Accounts",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },{
                module: "Expense Management",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },{
                module: "Expense Category",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },{
                module: "Notice Board",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },{
                module: "Payroll Report",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },{
                module: "Expense Report",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },{
                module: "Benefit Services Report",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },{
                module: "Users",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            },
        ]
    },
    {
        app: "TMS",
        permissions: [
            {
                module: "Users",
                access: {
                    create: false,
                    view: false,
                    edit:false,
                    delete: false
                },
            }
        ]
    }
 ]