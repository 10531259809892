import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { fetchData, postData } from "../../../redux/api";
import { toast } from "react-toastify";
type PasswordField = "oldPassword" | "newPassword" | "confirmPassword";

const ResetPassword3 = () => {
  const resetId = useParams().id;
  const routes = all_routes;
  const navigation = useNavigate();
  const [newPass,setNewPass]=useState("")
  const [confirmNewPass,setConfirmNewPass]=useState("")
  const [loading,setLoading]=useState(false)

  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field: PasswordField) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  useEffect(() => {
    if(!resetId){
      navigation(-1)
    }

    fetchData("/login/verify-password-update-token?token="+resetId)
    .then(resp => {
      if(resp.message === "Invalid token provided!") {
        toast(resp.message, { type: "error"})
        navigation('/')
      }else {

      }
      // console.log(resp)
    }).catch(err=>{
      console.log(err)
    })
  }, [resetId])

  const handleSubmit = (e:any) => {
    e.preventDefault()
    if(newPass===""||confirmNewPass===""){
      toast("Please complete the form.", { type: 'error' })
    } else if (newPass !== confirmNewPass){
      toast("New passwords do not match!", { type: 'error' })
    } else {
      setLoading(true)
      postData(`/login/update-password/${resetId}`, {
        newPassword: newPass
      })
      .then(resp => {
        // toast(resp.message)
        navigation(routes.resetPasswordSuccess)
      }).catch(err=>{
        console.log(err)
      }).finally(() => setLoading(false))
    }
  }

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5 mx-auto">
            <form>
              <div className="d-flex flex-column justify-content-between vh-100">
                <div className=" mx-auto p-4 text-center">
                  {/* <ImageWithBasePath
                    src="assets/img/orlu-united-banner.jpg"
                    className="img-fluid"
                    alt="Logo" width={150}
                  /> */}
                </div>
                <div className="card">
                  <div className="card-body p-4 pb-3">
                  <div className=" mx-auto p-4 text-center">
                  <ImageWithBasePath
                    src="assets/img/orlu-united-banner.jpg"
                    className="img-fluid"
                    alt="Logo" width={150}
                  />
                </div>
                    <div className=" mb-4">
                      <h2 className="mb-2">Reset Password?</h2>
                      <p className="mb-0">
                        Enter New Password &amp; Confirm Password to get into the app
                      </p>
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Old Password</label>
                      <div className="pass-group">
                        <input
                          type={
                            passwordVisibility.oldPassword ? "text" : "password"
                          }
                          className="pass-input form-control"
                        />
                        <span
                          className={`ti toggle-passwords ${
                            passwordVisibility.oldPassword
                              ? "ti-eye"
                              : "ti-eye-off"
                          }`}
                          onClick={() =>
                            togglePasswordVisibility("oldPassword")
                          }
                        ></span>
                      </div>
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label">New Password</label>
                      <div className="pass-group">
                        <input value={newPass} onChange={({target:{value}})=>setNewPass(value)}
                          type={
                            passwordVisibility.newPassword ? "text" : "password"
                          }
                          className="pass-input form-control"
                        />
                        <span
                          className={`ti toggle-passwords ${
                            passwordVisibility.newPassword
                              ? "ti-eye"
                              : "ti-eye-off"
                          }`}
                          onClick={() =>
                            togglePasswordVisibility("newPassword")
                          }
                        ></span>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label ">
                        New Confirm Password
                      </label>
                      <div className="pass-group">
                        <input value={confirmNewPass} onChange={({target:{value}})=>setConfirmNewPass(value)}
                          type={
                            passwordVisibility.confirmPassword
                              ? "text"
                              : "password"
                          }
                          className="pass-input form-control"
                        />
                        <span
                          className={`ti toggle-passwords ${
                            passwordVisibility.confirmPassword
                              ? "ti-eye"
                              : "ti-eye-off"
                          }`}
                          onClick={() =>
                            togglePasswordVisibility("confirmPassword")
                          }
                        ></span>
                      </div>
                    </div>
                    <div className="mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        onClick={handleSubmit} disabled={loading}
                      >
                        {loading ? "Please wait..." : "Change Password"}
                      </button>
                    </div>
                    <div className="text-center">
                      <h6 className="fw-normal text-dark mb-0">
                        Return to
                        <Link to={'/'} className="hover-a ">
                          {" "}
                          Login
                        </Link>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="p-4 text-center">
                  <p className="mb-0 ">Copyright © 2024 - Hogar Tech</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword3;
