import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PredefinedDateRanges from "../../../core/common/datePicker";
import { TableData } from "../../../core/data/interface";
import Table from "../../../core/common/dataTable/index";
import { permission } from "../../../core/data/json/permission";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";
import { permissionData } from "./permissionData";
import { Row } from "react-bootstrap";
import { Button } from "antd";
import {  fetchAuthenticatedData, putAuthenticatedData } from "../../../redux/api";
import { toast } from "react-toastify";
import Loading from "../../../core/common/Loading";

type PermissionType = {
    module: string;
    access: {
        create: boolean,
        view: boolean,
        edit: boolean,
        delete: boolean,
    }
}

type AppPermissionType = {
    app: string;
    permissions: PermissionType[]
}

type PermissionArrayType = AppPermissionType[]


const DesignationPermissions = () => {
  const data = permissionData;
  const routes = all_routes;
  const designationId = useParams().id
  
  const [loading, setLoading] = useState(false)
  const [fetching,setFetching] =useState(false)
  const [selectedPermissions, setSelectedPermissions] = useState<PermissionArrayType>([])
  // const [designationDetails,setDesignationDetails] = useState([])

  const getPrivileges = useCallback(() => {
    fetchAuthenticatedData(`/api/designations/${designationId}`)
    .then(resp => {
      if(resp && resp.privileges && resp.privileges) {
        // if(resp?.privileges === "{}") {}
        // else 
        if (Object.keys(resp?.privileges).length === 0){}
        else {
          // console.log(resp?.privileges)
        setSelectedPermissions((resp?.privileges))
      }}
    }).catch(err => console.log(err))
    .finally(() => setFetching(false))
  }, [designationId])

  useEffect(()=>{getPrivileges()},[getPrivileges])

  const handleChecked = (
    appName: string,
    moduleName: string,
    field: keyof PermissionType["access"]
  ) => {
    setSelectedPermissions((prev) => {
      // Check if the app already exists in the state
      let app = prev.find((appPermission) => appPermission.app === appName);
  
      if (!app) {
        // If the app doesn't exist, add it with the current module and permission field
        return [
          ...prev,
          {
            app: appName,
            permissions: [
              {
                module: moduleName,
                access: {
                  create: field === "create",
                  view: field === "view",
                  edit: field === "edit",
                  delete: field === "delete",
                },
              },
            ],
          },
        ];
      }
  
      // If the app exists, check if the module exists
      const module = app.permissions.find(
        (permission) => permission.module === moduleName
      );
  
      if (!module) {
        // If the module doesn't exist, add it
        return prev.map((appPermission) => {
          if (appPermission.app === appName) {
            return {
              ...appPermission,
              permissions: [
                ...appPermission.permissions,
                {
                  module: moduleName,
                  access: {
                    create: field === "create",
                    view: field === "view",
                    edit: field === "edit",
                    delete: field === "delete",
                  },
                },
              ],
            };
          }
          return appPermission;
        });
      }
  
      // If the module exists, update the specific field
      return prev.map((appPermission) => {
        if (appPermission.app === appName) {
          return {
            ...appPermission,
            permissions: appPermission.permissions.map((permission) => {
              if (permission.module === moduleName) {
                return {
                  ...permission,
                  access: {
                    ...permission.access,
                    [field]: !permission.access[field], // Toggle the value
                  },
                };
              }
              return permission;
            }),
          };
        }
        return appPermission;
      });
    });
  };

  const permissionFields: Array<keyof PermissionType["access"]> = [
    "create",
    "view",
    "edit",
    "delete",
  ];

  const renderCheckbox = (
    appName: string,
    moduleName: string,
    field: keyof PermissionType["access"]
  ) => {
    const app = selectedPermissions?.find((a) => a.app === appName);
    const module = app?.permissions?.find((p) => p.module === moduleName);
    const checked = module?.access[field] ?? false;
  
    return (
      <label className="checkboxs">
        <input
          type="checkbox"
          checked={checked}
          onChange={() => handleChecked(appName, moduleName, field)}
        />
        <span className="checkmarks" />
      </label>
    );
  };
  

  // const renderCheckbox = (field: string, record: any) => {
  //   const appSelected = selectedPermissions?.find((a: any) => a.app === record.app);
  //   const checked = appSelected?.permission?.[record.modules]?.[field] || false;
  
  //   return (
  //     <label className="checkboxs">
  //       <input
  //         type="checkbox"
  //         checked={checked}
  //         onChange={() => handleChecked(record, record.modules, field)}
  //       />
  //       <span className="checkmarks" />
  //     </label>
  //   );
  // };
  

  // const columns = [
  //   {
  //     title: "Modules",
  //     dataIndex: "modules",
  //     sorter: (a: any, b: any) => a.modules.length - b.modules.length,
  //   },
  //   ...permissionFields.map((field) => ({
  //     title: field.charAt(0).toUpperCase() + field.slice(1),
  //     dataIndex: field,
  //     render: (text: string, record: any) => renderCheckbox(field, record),
  //   })),
  // ];

  const columns = (app:AppPermissionType) => [
    {
      title: "Modules",
      dataIndex: "module",
      sorter: (a: PermissionType, b: PermissionType) =>
        a.module.length - b.module.length,
    },
    ...permissionFields.map((field) => ({
      title: field.charAt(0).toUpperCase() + field.slice(1),
      dataIndex: field,
      render: (_: any, record: PermissionType) =>
        renderCheckbox(app.app, record.module, field),
    })),
  ];

  const savePrivilege = () => {
    setLoading(true)
    putAuthenticatedData(`/api/designations/${designationId}`, {
      privileges: selectedPermissions,
    }).then((resp) => {
      toast(resp.message)
    }).catch(err => {
      toast(err.message, { type: "error" })
    }).finally(()=>setLoading(false))
  }

  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Designation Permissions</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">User Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Designation Permissions
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                {/* <TooltipOption /> */}
                <Button onClick={savePrivilege} loading={loading}
                  // to="#"
                  // className="dropdown-toggle btn btn-light fw-medium d-inline-flex align-items-center"
                  // data-bs-toggle="dropdown"
                >
                  <i className="ti ti-file-export me-2" />
                  Save
                </Button>
              </div>
            </div>
            {/* /Page Header */}

            {/* {JSON.stringify(designationDetails)} */}
            {fetching && <Loading />}

            <Row>
              {data.map((value: AppPermissionType, idx: number) => {
                const appData = value.permissions;

                return (
                  <div className="col-md-6 card" key={idx}>
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                      <h4 className="mb-3">{value.app}</h4>
                    </div>
                    <div className="card-body p-0 py-3">
                      <Table
                        noSearch
                        columns={columns(value)}
                        dataSource={appData}
                        // rowKey={(record:any) => record.module}
                      />
                    </div>
                  </div>
                );
              })}
            </Row>

          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default DesignationPermissions;
