import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { authRoutes, publicRoutes } from "./router.link";
import Feature from "../feature";
import AuthFeature from "../authFeature";
import Login from "../auth/login/login";
import { ToastContainer } from 'react-toastify';
import ForgotPassword from "../auth/forgotPassword/forgotPassword";
import ResetPassword from "../auth/resetPassword/resetPassword-3";
import ResetPasswordSuccess3 from "../auth/resetPasswordSuccess/resetPasswordSuccess-3";

const ALLRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/"  element={<Login/>} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/update-password/:id" element={<ResetPassword />} />
        <Route path="/reset-password-success" element={<ResetPasswordSuccess3 />} />
        <Route element={<Feature />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>

        <Route element={<AuthFeature />}>
          {authRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default ALLRoutes;
