import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { AUTH_KEY, fetchAuthenticatedData, postData } from "../../../redux/api";
import { toast } from "react-toastify";
import { setUser } from "../../../core/data/redux/authSlice";
import { useDispatch } from "react-redux";

interface LoginForm {
  username: string;
  password: string;
  appName:string
}

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const routes = all_routes;
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [form, setForm] = useState<LoginForm>({
    username: '',
    password: '',
    appName: "HR Suite"
  })
  const [logginIn, setLogginIn] = useState(false)

  const handleChange = (e: any) => {
    setForm(prev => ({...prev, [e.target.name]: e.target.value }))
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  useEffect(() => {
    localStorage.setItem("menuOpened", "Dashboard");
  }, []);
  const date = () => {
    return new Date().getFullYear();
  };

  const handleSubmit = (e:any) => {
    e.preventDefault()
    setLogginIn(true)
    postData('/login', form)
    .then((res) => {
      localStorage.setItem(AUTH_KEY, res.token)
      // console.log('res', res, 'res')
      if(res && res.token) {

        // init
        fetchAuthenticatedData("/checkToken")
        .then((res) => {
    setLogginIn(false)
    if(res&&res.message&&res.message==="Access denied: Wrong access token"){
            // console.log(`Access: ${state.isAuthenticated}`);
            // localStorage.removeItem('token')
            // setState(prev => ({...prev, loading:false, redirect: true }))
            // navigate('/')
          } else {
            // setState(prev=>({...prev, isAuthenticated: true, loading:false  }));
            // console.log(`Access: ${state.isAuthenticated}`);
            localStorage.setItem('user', JSON.stringify(res.authData.user))
            dispatch(setUser(res.authData.user))

            if(res.authData.user.role === 'ROLE_ADMIN') {
              navigate(routes.adminDashboard)
            } else if(res.authData.user.role === 'ROLE_MANAGER') {
              navigate(routes.managerDashboard)
            } else if(res.authData.user.role === 'ROLE_EMPLOYEE') {
              navigate(routes.userDashboard)
            }
          }
        })
        .catch((err) => {
    setLogginIn(false)
          console.log(err)
          // console.log(`Access: ${state.isAuthenticated}`);
          // localStorage.removeItem('user')
          // localStorage.removeItem('token')
          // setState(prev => ({...prev, loading:false, redirect: true }))
        });
      } else {
    setLogginIn(false)
    toast(res.message, { type: 'error'})
        // console.log(res, '<======res')/
      }
      // this.setState({done: true})
    })
    .catch((err) => {
    setLogginIn(false)
    console.log(err.response);
      toast(err.message, { type: 'error' })
      // this.setState({
      //   hasError: true,
      //   errorMessage: err.response.data.message
      // });
    });

   
      
  };

  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          {/* <div className="col-lg-6">
            <div className="login-background d-lg-flex align-items-center justify-content-center d-lg-block d-none flex-wrap vh-100 overflowy-auto">
              <div>
                <ImageWithBasePath
                  src="assets/img/authentication/authentication-02.jpg"
                  alt=""
                />
              </div>
              <div className="authen-overlay-item  w-100 p-4">
                <h4 className="text-white mb-3">What's New at OUMC !!!</h4>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Summer Vacation Holiday Homework</h6>
                    <p className="mb-0 text-truncate">
                      The school will remain closed from April 20th to June...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>New Academic Session Admission Start(2024-25)</h6>
                    <p className="mb-0 text-truncate">
                      An academic term is a portion of an academic year, the
                      time ....
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Date sheet Final Exam Nursery to Sr.Kg</h6>
                    <p className="mb-0 text-truncate">
                      Dear Parents, As the final examination for the session
                      2024-25 is ...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-3 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Annual Day Function</h6>
                    <p className="mb-0 text-truncate">
                      Annual functions provide a platform for students to
                      showcase their...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
                <div className="d-flex align-items-center flex-row mb-0 justify-content-between p-3 br-5 gap-3 card">
                  <div>
                    <h6>Summer Vacation Holiday Homework</h6>
                    <p className="mb-0 text-truncate">
                      The school will remain closed from April 20th to June 15th
                      for summer...
                    </p>
                  </div>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-4 mx-auto p-4">
                <form>
                  <div>
                    
                      <div className="card">
                      <div className="card-body pb-3">
                      <div className=" mx-auto mb-5 text-center">
                    <ImageWithBasePath
                        src="assets/img/orlu-united-banner.jpg"
                        // className="img-fluid w-20"
                        // height={40}
                        width={150}
                        alt="Logo"
                      />
                    </div>
                        <div className=" mb-4">
                          <h2 className="mb-2">Welcome to OUMC HR Suite</h2>
                          <p className="mb-0">
                            Please enter your details to sign in
                          </p>
                        </div>
                        {/* <div className="mt-4">
                          <div className="d-flex align-items-center justify-content-center flex-wrap">
                            <div className="text-center me-2 flex-fill">
                              <Link
                                to="#"
                                className="bg-primary br-10 p-2 btn btn-primary  d-flex align-items-center justify-content-center"
                              >
                                <ImageWithBasePath
                                  className="img-fluid m-1"
                                  src="assets/img/icons/facebook-logo.svg"
                                  alt="Facebook"
                                />
                              </Link>
                            </div>
                            <div className="text-center me-2 flex-fill">
                              <Link
                                to="#"
                                className=" br-10 p-2 btn btn-outline-light  d-flex align-items-center justify-content-center"
                              >
                                <ImageWithBasePath
                                  className="img-fluid  m-1"
                                  src="assets/img/icons/google-logo.svg"
                                  alt="Facebook"
                                />
                              </Link>
                            </div>
                            <div className="text-center flex-fill">
                              <Link
                                to="#"
                                className="bg-dark br-10 p-2 btn btn-dark d-flex align-items-center justify-content-center"
                              >
                                <ImageWithBasePath
                                  className="img-fluid  m-1"
                                  src="assets/img/icons/apple-logo.svg"
                                  alt="Apple"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="login-or">
                          <span className="span-or">Or</span>
                        </div> */}
                        {/* {JSON.stringify(form)} */}
                        <div className="mb-3 ">
                          <label className="form-label">Username</label>
                          <div className="input-icon mb-3 position-relative">
                            <span className="input-icon-addon">
                              <i className="ti ti-user" />
                            </span>
                            <input
                              type="text"
                              defaultValue=""
                              name="username"
                              value={form.username}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                          <label className="form-label">Password</label>
                          <div className="pass-group">
                            <input
                              type={isPasswordVisible ? "text" : "password"}
                              className="pass-input form-control"
                              name="password"
                              value={form.password}
                              onChange={handleChange}
                            />
                            <span
                              className={`ti toggle-password ${
                                isPasswordVisible ? "ti-eye" : "ti-eye-off"
                              }`}
                              onClick={togglePasswordVisibility}
                            />
                          </div>
                        </div>
                        <div className="form-wrap form-wrap-checkbox">
                          <div className="d-flex align-items-center">
                            {/* <div className="form-check form-check-md mb-0">
                              <input
                                className="form-check-input mt-0"
                                type="checkbox"
                              />
                            </div>
                            <p className="ms-1 mb-0 ">Remember Me</p> */}
                          </div>
                          <div className="text-end ">
                            <Link to={routes.forgotPassword}className="link-danger">
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="p-4 pt-0">
                        <div className="mb-3">
                          <button
                            onClick={handleSubmit}
                            className="btn btn-primary w-100"
                            disabled={logginIn}
                          >
                            {logginIn ? "Logging In..." : "Login"}
                          </button>
                        </div>
                        {/* <div className="text-center">
                          <h6 className="fw-normal text-dark mb-0">
                            Don’t have an account?{" "}
                            <Link to={routes.register} className="hover-a ">
                              {" "}
                              Create Account
                            </Link>
                          </h6>
                        </div> */}
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <p className="mb-0 ">Copyright © {date()} - Hogar Tech</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
